<template>
  <div>
    <div v-if="local">
      <div
        v-if="!id || !Object.keys(editable).includes('name') || editable.name"
      >
        <p
          v-if="id"
          class="mb-4 bg-grey-30 px-4 py-3 text-sm"
        >
          When you change the details of a contact here, their details will be
          updated everywhere you have included them in your account.
        </p>

        <h3 class="mb-4">Full Name</h3>
        <v-text-field
          v-model="local.name"
          v-bind="textField"
          placeholder="Name"
          @input="
            () => {
              showErrorsSwitch(true, 'name')
              showErrorsSwitch(false, 'existing')
            }
          "
          @blur="
            () => {
              showErrorsSwitch(true, 'name')
              showErrorsSwitch(false, 'existing')
            }
          "
          :error-messages="
            showErrors.name && errorMessages.name ? errorMessages.name : null
          "
        />
      </div>

      <div v-if="editable && editable.address">
        <template
          v-if="id && Object.keys(editable).includes('name') && !editable.name"
        >
          <h2 class="mb-4">Required fields for {{ local.name }}</h2>
        </template>
        <h3>Address</h3>
        <address-picker
          v-model="local.address"
          v-bind="textField"
          label=""
          placeholder="Address"
          @input="showErrorsSwitch(true, 'address')"
          @blur="showErrorsSwitch(true, 'address')"
          :error-messages="
            showErrors.address && errorMessages.address
              ? errorMessages.address
              : null
          "
        />
      </div>
      <div v-if="editable && editable.phone">
        <h3 class="mb-4">
          Phone number
          <span
            v-if="recommended.includes('phone')"
            class="recommended"
          >
            (Recommended)
          </span>
        </h3>
        <div
          v-if="showPhoneWarning"
          class="phone-warning"
        >
          Are you sure this is a valid phone number?
        </div>
        <v-text-field
          v-bind="textField"
          placeholder="eg 0400123456"
          hint="Mobile or landline with no spaces."
          v-model="local.phone"
          maxlength="18"
          :error-messages="
            showErrors.phone && errorMessages.phone ? errorMessages.phone : null
          "
          @blur="handleBlurPhone"
        />
      </div>
      <div v-if="editable && editable.email">
        <h3 class="mb-4">
          Email address
          <span
            v-if="recommended.includes('email')"
            class="recommended"
          >
            (Recommended)
          </span>
        </h3>
        <v-text-field
          outlined
          v-model="local.email"
          type="email"
          @input="showErrorsSwitch(true, 'email')"
          @blur="showErrorsSwitch(true, 'email')"
          :error-messages="
            showErrors.email && errorMessages.email ? errorMessages.email : null
          "
        />
      </div>
      <div v-if="editable && editable.dob">
        <h3 class="mb-4">Date of birth</h3>
        <date-field
          v-bind.sync="local.dob"
          :disabled="false"
          :valid.sync="dateValid"
          :not-required="true"
          :is-adult="isAdult || local.isAdult"
        />
      </div>
      <div v-if="editable && editable.occupation">
        <h3 class="mb-4">
          Occupation
          <span
            v-if="recommended.includes('occupation')"
            class="recommended"
          >
            (Recommended)
          </span>
        </h3>
        <v-text-field
          outlined
          v-model="local.occupation"
          type="text"
          @blur="showErrors.occupation = true"
          @focus="showErrors.occupation = false"
          :error-messages="
            showErrors.occupation && errorMessages.occupation
              ? errorMessages.occupation
              : null
          "
        />
      </div>
      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
        >
          <template
            v-if="
              id && Object.keys(editable).includes('name') && !editable.name
            "
          >
            Continue
          </template>
          <template v-else> Save </template>
        </v-btn>
      </div>
      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="showErrors.existing && errorMessages.existing"
          v-html="errorMessages.existing"
          class="align-center red rounded pa-4 mt-4 text-white"
        ></div>
      </transition>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'

import DateField from '@/components/fields/DateField'

import AddressPicker from '../ui/AddressPicker'

export default {
  name: 'WillFormPerson',
  components: { AddressPicker, DateField },
  props: {
    closeLabel: {
      default: 'close',
    },
    id: {
      default: null,
    },
    excluded: {
      default() {
        return []
      },
    },
    editable: {
      default() {
        return {
          name: true,
          address: false,
          phone: false,
          email: false,
          dob: false,
          occupation: false,
        }
      },
    },
    required: {
      default() {
        return []
      },
    },
    recommended: {
      default() {
        return []
      },
    },
    isAdult: {
      default: false,
    },
  },
  data: () => ({
    local: null,
    showErrors: {
      name: false,
      address: false,
      phone: false,
      email: false,
      existing: false,
      occupation: false,
    },
    processing: false,
    message: null,
    dateValid: false,
    showPhoneWarning: false,
  }),
  mounted() {
    const clone = cloneDeep(this.entity_person)
    this.local = {
      ...clone,
      dob:
        clone.dob && clone.dob.day && clone.dob.month && clone.dob.year
          ? clone.dob
          : { day: '', month: '', year: '' },
    }
  },
  watch: {
    'local.phone': {
      handler: function (val) {
        if (val.length === 0) this.showPhoneWarning = false
        else if (this.isValidAustralianNumber(val))
          this.showPhoneWarning = false
      },
    },
  },
  methods: {
    handleBlurPhone() {
      this.showErrorsSwitch(true, 'phone')
      if (this.local.phone.length > 0)
        this.showPhoneWarning = !this.isValidAustralianNumber(this.local.phone)
    },
    async submit() {
      this.processing = true
      if (this.isValid) {
        let id = this.id
        if (this.isChanged) {
          try {
            id = await this.save()
          } catch (error) {
            this.message = error.message
            this.showErrorsSwitch(true, 'existing')
            this.processing = false
            return
          }
        }
        setTimeout(() => {
          this.next(id)
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      const validation = await this.$store.dispatch(
        'account/validate_entity_person',
        {
          name: this.localFormatted.name,
        }
      )

      if (!this.id) {
        if (validation) {
          if (validation.type === 'SameAsProfileError') {
            throw new Error(validation.message)
          }

          if (this.excluded.includes(validation.person.id))
            throw new Error(`
              <h3>Duplicate Entry</h3>
              <div class="mt-2">
                <ul>
                  <li>You have already added someone with this name to your account.</li>
                  <li>Add in a new person or click back to select one of your eligible contacts.</li>
                  <li>If you can not see this person in the options, they may not be eligible to be added here.</li>
                </ul>
              </div>
          `)
          else {
            if (this.editable.address && this.local.address.length > 0)
              validation.person.address = this.local.address
            if (this.editable.phone && this.local.phone.length > 0)
              validation.person.phone = this.local.phone
            if (this.editable.email && this.local.email.length > 0)
              validation.person.email = this.local.email

            return await this.$store.dispatch('account/save_entity_person', {
              id: validation.person.id,
              person: validation.person,
            })
          }
        }
      }

      if (!this.id && validation && validation.message)
        throw new Error(validation.message)
      return await this.$store.dispatch('account/save_entity_person', {
        id: this.id,
        person: this.localFormatted,
      })
    },
    close() {
      this.$emit('close')
    },
    next(id = null) {
      this.$emit('next', id)
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    isValidAustralianNumber(number) {
      return number.length === 10 && /^[0-9+]*$/.test(number)
    },
  },
  computed: {
    entity_person() {
      return this.$store.getters['account/entity_person'](this.id)
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {}

      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })

      if (!/^[A-Za-z0-9\- ‘’'`]+$/.test(this.localFormatted.name))
        msgs.name = 'Invalid character'

      if (!this.localFormatted.name.length) msgs.name = 'Required'

      if (this.editable.email) {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (
          this.localFormatted.email?.length > 0 &&
          !pattern.test(this.localFormatted.email)
        )
          msgs.email = 'Not a valid email address'

        if (
          this.required.includes('email') &&
          !this.localFormatted.email.length
        )
          msgs.email = 'Required'
      }

      if (this.editable.phone) {
        if (
          this.localFormatted.phone?.length > 0 &&
          !/^[0-9+]*$/.test(this.localFormatted.phone)
        ) {
          msgs.phone = 'Should only contain numbers (no spaces)'
        }
      }

      if (
        this.editable.address &&
        this.required.includes('address') &&
        !this.localFormatted.address.length
      )
        msgs.address = 'Required'

      if (
        this.editable.occupation &&
        this.required.includes('occupation') &&
        !this.localFormatted.occupation.length
      )
        msgs.occupation = 'Required'

      if (this.showErrors.existing) msgs.existing = this.message
      return msgs
    },
    isValid() {
      return (
        Object.values(this.errorMessages).every((val) => val === null) &&
        (this.editable.dob ? this.dateValid : true)
      )
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.entity_person)
    },
    localFormatted() {
      let entity = {
        ...this.entity_person,
        name: this.local?.name.trim().replace(/\s\s+/g, ' ') || '',
      }

      if (this.editable.address) {
        entity = {
          ...entity,
          address: this.local.address?.trim(),
        }
      }
      if (this.editable.phone) {
        entity = {
          ...entity,
          phone: this.local.phone?.trim() || '',
        }
      }
      if (this.editable.email) {
        entity = {
          ...entity,
          email: this.local.email?.trim() || '',
        }
      }
      if (this.editable.occupation) {
        entity = {
          ...entity,
          occupation: this.local.occupation?.trim() || '',
        }
      }
      if (this.editable.dob) {
        entity = {
          ...entity,
          dob: this.local.dob || { day: '', month: '', year: '' },
        }
      }

      return entity
    },
  },
}
</script>

<style lang="scss">
.phone-warning {
  margin-top: -8px;
  color: #c2410c;
  margin-bottom: 2px;
  font-weight: 500;
}

.recommended {
  color: #1477b9;
  font-size: 16px;
}
</style>
